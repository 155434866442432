
"use client";

import { Open_Sans } from "next/font/google";
export const openSans = Open_Sans({
  subsets: ["latin"],
});

import {
  Fragment,
  useCallback,
  useState,
} from "react"; // GLOBAL CUSTOM COMPONENTS
import RTL from "components/rtl";
import ProgressBar from "components/progress"; // IMPORT i18n SUPPORT FILE
import "i18n";
import { usePathname } from "next/navigation";
import Divider from "@mui/material/Divider"; // GLOBAL CUSTOM COMPONENTS
import Sticky from "components/sticky";
import Topbar from "components/topbar";
import Header from "components/header";
import { Navbar } from "components/navbar";
import { SearchInput } from "components/search-box";
import { Footer1 } from "components/footer";
import { MobileNavigationBar2 } from "components/mobile-navigation";
// import { Analytics } from "@vercel/analytics/react"
import ContextComposer from "contexts/ContextComposer"
import { GoogleTagManager } from '@next/third-parties/google'


export default function RootLayout({ children }) {
  
  const pathname = usePathname();
  const [isFixed, setIsFixed] = useState(false);
  const toggleIsFixed = useCallback((fixed) => setIsFixed(fixed), []);

  // FOR HANDLE TOP BAR AREA
  // Top bar only on homepage
  let TOP_BAR_CONTENT = null;
  const SHOW_TOP_BAR_ON = ["/"];
  if (SHOW_TOP_BAR_ON.includes(pathname)) {
    TOP_BAR_CONTENT = <Topbar />;
  }

  let NAV_BAR_CONTENT = null;
  const HIDE_NAV_BAR = ["/checkout-alternative"];
  if (!HIDE_NAV_BAR.includes(pathname))
    NAV_BAR_CONTENT = <Navbar elevation={0} />;

  let HEADER_SEARCH_BAR_CONTENT = null;
  const HIDE_HEADER_SEARCH_BAR_CONTENT = [];
  if (!HIDE_HEADER_SEARCH_BAR_CONTENT.includes(pathname))
    HEADER_SEARCH_BAR_CONTENT = (
      <Sticky fixedOn={0} onSticky={toggleIsFixed} scrollDistance={70}>
        <Header isFixed={isFixed} midSlot={<SearchInput />} />
      </Sticky>
    );

  return (
    <html lang="en" suppressHydrationWarning>
      <body className={openSans.className}>
        <ContextComposer>
                  <ProgressBar />
                  <RTL>
                    <Fragment>
                      {/* TOP BAR SECTION */}
                      {TOP_BAR_CONTENT ?? <Divider />}

                      {/* HEADER */}
                      {HEADER_SEARCH_BAR_CONTENT ?? <Divider />}

                      {/* NAVIGATION BAR */}
                      {NAV_BAR_CONTENT ?? <Divider />}

                      {/* BODY CONTENT */}
                      {children}

                      {/* SMALL DEVICE BOTTOM NAVIGATION */}
                      <MobileNavigationBar2 />

                      {/* FOOTER */}
                      <Footer1 />
                    </Fragment>
                  </RTL>
        </ContextComposer>
        <GoogleTagManager gtmId={'GTM-5Z9476N3'} />
      </body>
    </html>
  );
}

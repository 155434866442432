
// Import all your context providers
import { CategoryProvider } from './CategoryContext';
import { ProductProvider } from './ProductContext';
import {CartProvider }from "./CartContext"
import ThemeProvider from '../theme/theme-provider';
import { UserProvider } from './UserContext';

// List of providers in the order they should be nested
const providers = [
  ThemeProvider,
  CartProvider,
  ProductProvider,
  CategoryProvider,
  UserProvider
];

const ContextComposer = ({ children }) => providers.reduceRight(
  (kids, Provider) => <Provider>{kids}</Provider>,
  children
);

export default ContextComposer;
